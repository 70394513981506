import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Stack, Heading, Text, Button } from '@chakra-ui/core'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Article from '../components/article'
import Section from '../components/section'

function NotFoundPage() {
  const { t } = useTranslation()

  return (
    <Layout>
      <SEO title={t('404.title')} />
      <Article>
        <Section>
          <Stack spacing={6} alignItems="center">
            <Heading>{t('404.title')}</Heading>
            <Text>{t('404.desc')}</Text>
            <Button size="lg" width="fit-content">
              {t('404.button')}
            </Button>
          </Stack>
        </Section>
      </Article>
    </Layout>
  )
}

export default NotFoundPage
